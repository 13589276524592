import methods from '@/enums/methods';
import Thoth from '../base/thoth';

class InstallmentsService extends Thoth {
    consultNsus({ nsus, date }) {
        return this.fetch({
            url: '/installments/consult/nsu-date',
            data: {
                nsu: nsus,
                date,
            },
            method: methods.POST,
        });
    }

    updateStatus({ installmentsIds, status: saleStatusId, username }) {
        return this.fetch({
            method: methods.PUT,
            url: '/installments/update/status',
            data: {
                installmentsIds, saleStatusId, username,
            },
        });
    }
}

export default new InstallmentsService();
