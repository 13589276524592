var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.processKey,staticClass:"d-flex flex-column",staticStyle:{"gap":"12px"}},[_c('b-row',[_c('b-col',{attrs:{"md":"9"}},[_c('b-form-select',{attrs:{"options":[
          { value: null, text: 'Selecione um Status'},
          { value: 2, text: 'Autorizado'},
          { value: 4, text: 'Cancelado'}
        ]},model:{value:(_vm.statusSelected),callback:function ($$v) {_vm.statusSelected=$$v},expression:"statusSelected"}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-button',{staticClass:"w-100",attrs:{"variant":"primary","disabled":!_vm.statusSelected},on:{"click":_vm.handleSave}},[_vm._v(" Salvar ")])],1)],1),_c('b-table',{staticClass:"text-center alert-table",attrs:{"items":_vm.installmentsDeepCloned,"fields":[
      { key: 'check', label: 'Selecionado' },
      { key: 'nsu', label: 'NSU' },
      { key: 'transactionDate', label: 'Data da Transação' },
      { key: 'installmentVeripagId', label: 'Veripag Id da Parcela' },
      { key: 'installmentNumber', label: 'Parcela' },
      { key: 'amountInstallment', label: 'valor' },
      { key: 'status', label: 'status' } ]},scopedSlots:_vm._u([{key:"thead-top",fn:function(ref){
    var fields = ref.fields;
return [_c('tr',{staticClass:"alert-header",attrs:{"role":"row"}},_vm._l((fields),function(field){return _c('th',{key:field.key},[(field.key !== 'check')?_c('span',[_vm._v(_vm._s(field.label))]):_c('b-form-checkbox',{on:{"change":_vm.verifyAll},model:{value:(_vm.isVerifiedAll),callback:function ($$v) {_vm.isVerifiedAll=$$v},expression:"isVerifiedAll"}})],1)}),0)]}},{key:"cell(check)",fn:function(data){return [_c('b-form-checkbox',{staticClass:"mx-auto",model:{value:(data.item.check),callback:function ($$v) {_vm.$set(data.item, "check", $$v)},expression:"data.item.check"}})]}},{key:"cell(transactionDate)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("date")(data.item.transactionDate))+" ")]}},{key:"cell(amountInstallment)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("value")(data.item.amountInstallment))+" ")]}},{key:"cell(status)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.status[data.item.status])+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }