<template>
  <div
    :key="processKey"
    style="gap: 12px;"
    class="d-flex flex-column"
  >
    <b-row>
      <b-col md="9">
        <b-form-select
          v-model="statusSelected"
          :options="[
            { value: null, text: 'Selecione um Status'},
            { value: 2, text: 'Autorizado'},
            { value: 4, text: 'Cancelado'}
          ]"
        />
      </b-col>

      <b-col md="3">
        <b-button
          variant="primary"
          class="w-100"
          :disabled="!statusSelected"
          @click="handleSave"
        >
          Salvar
        </b-button>
      </b-col>
    </b-row>

    <b-table
      class="text-center alert-table"
      :items="installmentsDeepCloned"
      :fields="[
        { key: 'check', label: 'Selecionado' },
        { key: 'nsu', label: 'NSU' },
        { key: 'transactionDate', label: 'Data da Transação' },
        { key: 'installmentVeripagId', label: 'Veripag Id da Parcela' },
        { key: 'installmentNumber', label: 'Parcela' },
        { key: 'amountInstallment', label: 'valor' },
        { key: 'status', label: 'status' },
      ]"
    >
      <template #thead-top="{ fields }">
        <tr
          role="row"
          class="alert-header"
        >
          <th
            v-for="field in fields"
            :key="field.key"
          >
            <span v-if="field.key !== 'check'">{{ field.label }}</span>
            <b-form-checkbox
              v-else
              v-model="isVerifiedAll"
              @change="verifyAll"
            />
          </th>
        </tr>
      </template>
      <template #cell(check)="data">
        <b-form-checkbox
          v-model="data.item.check"
          class="mx-auto"
        />
      </template>
      <template #cell(transactionDate)="data">
        {{ data.item.transactionDate | date }}
      </template>

      <template #cell(amountInstallment)="data">
        {{ data.item.amountInstallment | value }}
      </template>

      <template #cell(status)="data">
        {{ status[data.item.status] }}
      </template>
    </b-table>
  </div>
</template>

<script>
import {
    BTable, BFormSelect,
    BFormCheckbox, BRow, BCol, BButton,
} from 'bootstrap-vue';

export default {
    components: {
        BTable, BFormSelect, BFormCheckbox, BRow, BCol, BButton,
    },

    props: {
        items: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            installmentsDeepCloned: JSON.parse(JSON.stringify(this.items)),
            processKey: 1,
            statusSelected: null,
            isVerifiedAll: false,
            status: {
                1: 'Processando',
                2: 'Autorizado',
                3: 'Aguardando pagamento',
                4: 'Cancelado',
                5: 'Pago',
                7: 'Recusado',
                8: 'Estornado',
                9: 'Antecipado',
                16: 'Expirado',
                18: 'Baixa',
                19: 'Compensado',
                20: 'Pendente',
                21: 'Análise',
                22: 'Expiração',
                23: 'Contagem',
                25: 'Cedido',
                27: 'Contagem',
                28: 'Autorizado em contestação',
                29: 'Pago em contestação',
                30: 'Antecipado em contestação',
            },
        };
    },
    watch: {
        isVerifiedAll() {
            this.verifyAll(this.isVerifiedAll);
        },

        items: {
            handler() {
                this.installmentsDeepCloned = JSON.parse(JSON.stringify(this.items));
            },
            deep: true,
        },
    },

    methods: {
        verifyAll(selected) {
            this.installmentsDeepCloned.forEach(item => {
                // eslint-disable-next-line no-param-reassign
                item.check = selected;
            });
            this.processKey += 1;
        },

        handleSave() {
            const filtered = this.installmentsDeepCloned.filter(item => item.check);
            this.$emit('saved', {
                ids: filtered.map(item => item.installmentId),
                status: this.statusSelected,
            });

            // eslint-disable-next-line no-restricted-syntax
            for (const item of filtered) {
                item.status = this.statusSelected;
            }
        },
    },
};
</script>

<style>
.alert-table thead tr {
  display: none
}

.alert-table thead tr.alert-header  {
  display: table-row
}
</style>
